import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import Button from "../../atoms/Button"
import SendIcon from "../../../images/icons/send.svg"

const ContactForm = props => {
  const textAreaRef = useRef(null)
  const [text, setText] = useState("")
  const [textAreaHeight, setTextAreaHeight] = useState("auto")
  const [parentHeight, setParentHeight] = useState("auto")
  const [fieldErrors, setFieldErrors] = useState({})
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    message: "",
  })
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })

  useEffect(() => {
    setParentHeight(`${textAreaRef.current.scrollHeight}px`)
    setTextAreaHeight(`${textAreaRef.current.scrollHeight}px`)
  }, [text])

  const handleTextareaOnChange = event => {
    setTextAreaHeight("auto")
    setParentHeight(`${textAreaRef.current.scrollHeight}px`)
    setText(event.target.value)
    if (props.onChange) {
      props.onChange(event)
    }
    event.persist()
    setInputs(prev => ({
      ...prev,
      [event.target.id]: event.target.value,
    }))
  }

  const validationRules = {
    email: val => val && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val),
    message: val => !!val,
    name: val => !!val,
  }

  const validate = () => {
    let errors = {}
    let hasErrors = false
    for (let key of Object.keys(inputs)) {
      errors[key] = !validationRules[key](inputs[key])
      hasErrors |= errors[key]
    }
    setFieldErrors(prev => ({ ...prev, ...errors }))
    return !hasErrors
  }

  const renderFieldError = field => {
    if (fieldErrors[field]) {
      return (
        <div className="text-red text-sm mt-2">
          Please enter a valid {field}.
        </div>
      )
    }
  }
  useEffect(() => {
    if (Object.keys(fieldErrors).length > 0) {
      validate()
    }
  }, [inputs]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnChange = event => {
    event.persist()
    setInputs(prev => ({
      ...prev,
      [event.target.id]: event.target.value,
    }))
  }

  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      setFieldErrors({})
      setInputs({
        name: "",
        email: "",
        message: "",
      })
    }
  }
  const handleOnSubmit = event => {
    event.preventDefault()
    if (!validate()) {
      return
    }
    setServerState({ submitting: true })
    axios({
      method: "POST",
      url: "https://formspree.io/mbjwgvbd",
      data: inputs,
    })
      .then(r => {
        handleServerResponse(
          true,
          "Thank you. Your message was sent. We'll be in touch"
        )
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error)
      })
  }

  return (
    <form onSubmit={handleOnSubmit}>
      <div className="mb-10">
        <label className="block relative text-base mb-4" htmlFor="name">
          Your name
        </label>
        <input
          id="name"
          className="text-xl"
          name="name"
          type="text"
          required
          onChange={handleOnChange}
          value={inputs.name}
        />
        {renderFieldError("name")}
      </div>
      <div className="mb-10">
        <label className="block relative text-base mb-4" htmlFor="email">
          E-mail
        </label>
        <input
          id="email"
          className="text-xl"
          name="email"
          type="email"
          required
          onChange={handleOnChange}
          value={inputs.email}
        />
        {renderFieldError("email")}
      </div>
      <div className="mb-10">
        <label className="block relative text-base mb-4" htmlFor="message">
          Your Idea
        </label>
        <div style={{ minHeight: parentHeight }}>
          <textarea
            {...props}
            ref={textAreaRef}
            rows={1}
            style={{
              height: textAreaHeight,
            }}
            id="message"
            onChange={handleTextareaOnChange}
            value={inputs.message}
            className="text-xl"
            name="message"
            type="message"
            required
          />
        </div>
      </div>
      <div className="text-xs mt-14 md:mt-16">
        By sending the message, you accept our Privacy Policy. The controller of
        your personal data is PeakMedia Sp. z o.o. with its registered office in
        Kraków (Pl. Na Groblach 21, 31-101 Kraków). PeakMedia Sp. z o.o. needs
        the contact information you provide to us in order to contact you about
        our products and services. For more information about our privacy
        practices and commitment to protecting your privacy check out our
        Privacy Policy.
      </div>
      <div className="mt-12 md:mt-14">
        <Button
          variant="contained"
          fullWidthMobile
          type="submit"
          disabled={serverState.submitting}
        >
          {serverState.submitting ? "Sending" : "Send"}
          <SendIcon className="ml-2" />
        </Button>
      </div>
      {serverState.status && (
        <div
          className={`bg-green-40 p-3 text-center mt-6 font-normal ${
            !serverState.status.ok ? "bg-red-40" : "bg-green-40"
          }`}
        >
          {serverState.status.msg}
        </div>
      )}
    </form>
  )
}

export default ContactForm
