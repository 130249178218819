import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactForm from "../components/organisms/ContactForm"

export const query = graphql`
  {
    pageFeaturedImage: file(
      absolutePath: { glob: "**/src/images/og/featured-image-contact.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
  }
`

const ContactPage = ({ data }) => {
  return (
    <Layout headerBg="bg-beige">
      <Seo
        title="Contact Us & Estimate your project | Peak11"
        metaFeaturedImage={
          data.pageFeaturedImage.childImageSharp.gatsbyImageData
        }
      />
      <section className="bg-beige pt-12 md:pt-36 pb-10 md:pb-24 relative overflow-hidden">
        <div className="relative z-10">
          <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
            <div className="relative z-10 lg:px-36">
              <h1 className="text-h0/m md:text-h0 mt-0.5">Let's talk</h1>
              <div className="flex justify-between items-end text-beige-160 text-lg md:text-3xl mt-8 leading-[1.3]">
                <div className="max-w-[450px]">
                  <p className="mb-0">
                    You are taking a decisive step in realizing your aim! Before
                    our first communication, what would you like us to know
                    about your project? Please provide a brief description.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
            <div className="lg:px-36 mt-14 md:mt-24">
              <div className="w-full max-w-[450px]">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
        <div className="hidden sm:block absolute top-0 left-[55%] mt-20 h-full contact-patern w-[516px]"></div>
        <div className="hidden sm:block absolute top-0 left-[55%] mt-20 h-full w-full contact-patern2 ml-[516px]"></div>
      </section>
    </Layout>
  )
}

export default ContactPage
